.navLink {
  @apply
    text-gray-300
    no-underline
    rounded-md
    active:underline
    hover:underline
    focus:bg-gray-800
    p-3
}

.navLinkRed {
  @apply
    text-red-500
    rounded-md
    py-3
    px-4
    no-underline
    hover:underline
}
