.container {
  position: fixed;
  width: 340px;
  right: 16px;
  top: 16px;
  z-index: 100;
}

.container a {
  @apply text-red-500 no-underline;
}
.container a:hover {
  @apply underline;
}